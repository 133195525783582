@import './variables.scss';

.authentication-container {
    padding: 40px 56px;
    min-height: 100vh;
}

.authentication-header--wrapper {
    margin-bottom: 40px;
}

.authentication-content--wrapper {
    min-height: 80vh;
}

.authentication-content {
    border-radius: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;

    label[for=email_otp_code], label[for=mobile_otp_code] {
        width: 100% !important;
    }
}

.authentication-block--img {
    display: flex;
    flex-direction: column;
    align-items: center;
}

// Social buttons
.social-authentication-button {
    width: 100%;
    height: 48px;
    position: relative;
    font-weight: 600;

    img {
        width: 28px;
        height: 28px;
        object-fit: contain;
        left: 15px;
        top: 10px;
        position: absolute;
    }
}

.social-authentication-button--facebook {
    background-color: #1877F2;
    color: $white-color;
    border: solid 1px #1877F2 !important;
}

.social-authentication-button--facebook:hover {
    background-color: #1877F2;
    color: $white-color;
}

.social-authentication-button--google {
    background-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.54);
    border: solid 1px #dadce0 !important;
}

.social-authentication-button--google:hover, .social-authentication-button--google:active {
    background-color: #FAFAFA;
    color: rgba(0, 0, 0, 0.54);
}

// progress bar
.authentication-progress-bar {
    position: absolute !important;
    left: 24px !important;
    top: -8px !important;
    width: calc(100% - 48px) !important;
}