@import './variables.scss';

.overview-content {
	.red-color {
		color: $secondary-color  !important;
	}

	.purple-color {
		color: $primary-color  !important;
	}

	.overview-header {
		position: relative;
		top: -30px;

		h3 {
			font-weight: 500;
		}

		h3.ant-typography,
		h2.ant-typography {
			margin-top: 0px;
			margin-bottom: 0px;
		}
	}

	.overview-main {
		.ant-card {
			position: relative;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
			border-radius: 20px;
		}

		.overview-card {
			background: $primary-color;
			color: $white-color  !important;
			height: 350px;

			.card-image {
				position: absolute;
				right: 0;
				top: 0;
			}

			h3.ant-typography {
				color: $white-color;
			}

			.card-plan {
				border-radius: 16px;
				min-height: 175px;
			}

			.card-footer {
				position: absolute;
				bottom: 16px;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				text-align: center;
			}
		}

		.overview-orders {
			min-height: 350px;

			.ant-list-split .ant-list-item {
				border-bottom: none;
			}

			.ant-btn {
				padding: 0px;
				width: 125px;
				color: $primary-color;
			}

			.list-button {
				font-size: 14px;
				font-weight: 500;
			}

			.count-list {
				border-radius: 8px;
				padding: 3px;
			}

			.green-box {
				color: #65B569;
				background: #F0FAF0;
			}

			.red-box {
				color: #E1092A;
				background: #F6E1E4;
			}

			.orange-box {
				color: #F46615;
				background: #FFE4BC;
			}
		}

		.overview-order-sumary {
			min-height: 350px;
		}
	}
}