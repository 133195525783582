@import '../variables.scss';
.filter-button {
    margin-right: 24px;
}

.search-input {
    border-radius: 8px !important;
    width: 400px;
}

.color-placeholder {
    color: $gray-color;
}